<template>

<div class="header" :class="{'is-white': white}">

	<h2>{{ title }}<small>{{ text }}</small></h2>

</div>

</template>

<script>

export default {

	props: ['text', 'white', 'title', 'icon'],
	
}

</script>

<style scoped>

.header h2 {
	font-size: 28px;
    font-weight: 500;
    text-align: center;
	margin-bottom: 40px;
    color: #444;
}

.is-mobile .header h2 {
	padding: 0px 10px;
	line-height: 24px;
	font-size: 20px;
	margin-bottom: 20px;
}

.header.is-white h2 {
	color: #fff;
}

.header  h2 small {
	display: block;
	font-size: 16px;
	font-weight: 300;
	margin-top: 8px;
}

.is-mobile .header h2 small {
	line-height: 20px;
	font-size: 16px;
}

</style>